import React from 'react'
import useMediaQuery from '../hooks/useMediaQueriees'
import Accordion from './Accordion'

interface IPrivilegesCard {
  items: Array<{ title: string; description: string }>
  isGold?: boolean
}

const PrivilegesCard: React.FC<IPrivilegesCard> = ({
  items,
  isGold = false,
}) => {
  const isBreakpoint = useMediaQuery(767)

  return (
    <Accordion.Root
      type='single'
      className='w-full'
      defaultValue={items[0].title}
      collapsible
    >
      {items.map((item, index) => (
        <Accordion.Item value={item.title} className='mt-4 w-full' key={index}>
          <Accordion.Header
            className={`${
              isGold && index !== items.length - 1
                ? 'gradient-orange-pb'
                : 'bg-pink'
            } w-full rounded-2xl p-4`}
          >
            <Accordion.Trigger>
              <span className='text-white text-lg md:text-xl font-semibold text-left w-11/12'>
                {item.title}
              </span>
              <Accordion.Plus color={'white'} size={isBreakpoint ? 20 : 30} />
            </Accordion.Trigger>
            <Accordion.Content>
              <span className='text-white text-sm md:text-lg break-words text-left'>
                {item.description}
              </span>
            </Accordion.Content>
          </Accordion.Header>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  )
}

export default PrivilegesCard

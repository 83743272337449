import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../components/Containers/Card'
import PrimaryButton from '../components/Inputs/PrimaryButton'
import Subtitle from '../components/Typography/Subtitle'
import Title from '../components/Typography/Title'
import { useRouter } from 'next/router'
import ProjectCard from '../components/Containers/ProjectCard'
import SectionEndCurvy from '../components/SVG/SectionEndCurvy'
import useMediaQuery from '../hooks/useMediaQueriees'
import Divider from '../components/Divider'

interface IProjects {}

const Projects: React.FC<IProjects> = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const isMobile = useMediaQuery(1024)

  const renderAvailable = () => {
    return (
      <Card className='bg-green box-shadow-sm self-start mt-6'>
        <h2 className='text-white px-4 py-3 font-bold text-md lg:text-2xl'>
          {t('projects.available')}
        </h2>
      </Card>
    )
  }

  const renderSoon = () => {
    return (
      <Card className='bg-cyan box-shadow-sm self-start mt-6'>
        <h2 className='text-white px-4 py-3 font-bold text-md lg:text-2xl'>
          {t('projects.soon')}
        </h2>
      </Card>
    )
  }

  const renderSoldOut = () => {
    return (
      <Card className='bg-red box-shadow-sm self-start mt-6'>
        <h2 className='text-white px-4 py-3 font-bold text-md lg:text-2xl'>
          {t('projects.soldOut')}
        </h2>
      </Card>
    )
  }

  return (
    <div className='relative'>
      <Divider
        fillColor='#FFFFFF11'
        className='absolute bottom-[30%] lg:bottom-0'
      />
      <SectionEndCurvy />
      <div
        className='container mx-auto flex flex-col justify-start items-center px-4 pb-10'
        id='projects'
      >
        <div className='-mt-16 lg:-mt-40 2xl:-mt-80'>
          <Title label={t('projects.title')} />
        </div>
        <div className='mt-20'></div>
        <ProjectCard
          bgColor='bg-orange'
          imagePath='/assets/images/projects/modolls.png'
          title={t('projects.dolls.title')}
          description={t('projects.dolls.description')}
          cta={t('projects.dolls.cta')}
          onClick={() => {
            open('https://opensea.io/collection/social-modolls')
          }}
          badge={renderSoldOut}
        />
        <div className='mt-20'></div>
        <ProjectCard
          bgColor='bg-violet'
          imagePath='/assets/images/projects/factoryWorkers.png'
          title={t('projects.factoryWorkers.title')}
          description={t('projects.factoryWorkers.description')}
          bgClass='bg-orange'
          bgShadowClass='bg-brown'
          border='border-brown'
          reverse
          cta={t('projects.factoryWorkers.cta')}
          onClick={() => {
            router.push('/factory-workers')
          }}
          badge={renderAvailable}
        />
        <div className='mt-20'></div>
        <ProjectCard
          bgColor='bg-orange'
          imagePath='/assets/images/projects/AOF.png'
          title={t('projects.aof.title')}
          description={t('projects.aof.description')}
          badge={renderSoon}
        />

        <div className='flex flex-col lg:flex-row justify-start items-center w-full 2xl:w-2/3 mt-40 relative'>
          {isMobile === 0 && (
            <div className='absolute right-0 w-1/2 z-50'>
              <Image
                src={'/assets/images/manifesto/experimentation.png'}
                alt='Illustration experimentation'
                className='hidden lg:block'
                width={960}
                height={540}
              />
            </div>
          )}
          {isMobile === 1 && (
            <Image
              src={'/assets/images/manifesto/experimentation.png'}
              alt='Illustration experimentation'
              className='block lg:hidden'
              width={960}
              height={540}
            />
          )}

          <div className='w-full lg:w-7/12 lg:mr-8 mb-10 lg:mb-0 relative'>
            <Card>
              <div className='flex flex-col justify-center items-start px-4 pb-4 xl:px-10 xl:pb-10 -mt-20 xl:-mt-20 2xl:-mt-10'>
                <span className='z-30'>
                  <Subtitle label={t('nftToPlay.title')} align='text-left' />
                </span>
                <h3 className='text-white font-normal text-xl mt-4'>
                  {t('nftToPlay.description')}
                </h3>
                <div className='flex flex-row justify-center items-center -mb-16 mt-4'>
                  <PrimaryButton
                    title={t('manifesto.discoverMore')}
                    bgClass='bg-orange'
                    bgShadowClass='bg-brown'
                    border='border-brown'
                    full
                    onClick={() => router.push('/factory-workers')}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects

import React from 'react'
import useMediaQuery from '../../hooks/useMediaQueriees'

interface ITitle {
  label: string
  align?: 'text-center' | 'text-left' | 'text-right'
  self?: 'self-center' | 'self-start' | 'self-end' | 'self-auto'
}

const Title: React.FC<ITitle> = ({
  label,
  align = 'text-center',
  self = 'self-auto',
}) => {
  const isMobile = useMediaQuery(1024)

  return (
    <span className={`relative ${self}`}>
      <h2
        className={`font-black text-yellow ${
          isMobile ? 'text-shadow-10' : 'text-shadow-15'
        } text-6xl lg:text-8xl text-stroke-3 ${align}`}
      >
        {label}
      </h2>
    </span>
  )
}

export default Title

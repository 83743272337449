import React from 'react'

interface IDevais {
  bgColor?: string
  fillColor?: string
  className?: string
  onBlur: () => void
  onFocus: () => void
  onClick: () => void
}

const Devais: React.FC<IDevais> = ({
  bgColor,
  fillColor,
  className,
  onBlur,
  onFocus,
  onClick,
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 85.78 32.93'
    className={className}
    onBlur={onBlur}
    onFocus={onFocus}
    onMouseLeave={onBlur}
    onMouseEnter={onFocus}
    onClick={onClick}
  >
    <defs>
      <clipPath id='a' clipPathUnits='userSpaceOnUse'>
        <path d='M0 841.89h595.276V0H0Z' />
      </clipPath>
    </defs>
    <g clipPath='url(#a)' transform='matrix(.35278 0 0 -.35278 -58.702 174.64)'>
      <path
        style={{
          fill: fillColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0v43.426a3.32 3.32 0 0 1-1.629 2.858L-38.13 67.921a3.323 3.323 0 0 1-3.388 0L-78.02 46.284a3.322 3.322 0 0 1-1.628-2.858V0c0-1.173.619-2.26 1.628-2.858l36.502-21.637a3.323 3.323 0 0 1 3.388 0L-1.629-2.858A3.322 3.322 0 0 1 0 0'
        transform='translate(375.203 426.658)'
      />
      <path
        style={{
          fill: fillColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0c5.727.001 10.136-1.853 13.476-6.082 1.152-1.458 1.837-3.132.364-4.576-1.564-1.533-3.311-.866-4.636.605C5.861-6.344 1.778-5.154-2.965-6.298c-3.074-.741-4.988-2.841-5.147-5.36-.171-2.687 1.296-4.67 4.385-5.961 2.234-.934 4.614-1.298 6.946-1.839 1.875-.436 3.709-.982 5.457-1.804 4.484-2.107 6.944-5.594 6.967-10.682.022-5.062-2.289-8.859-6.766-10.844-7.446-3.301-14.643-2.455-21.3 2.363-.705.51-1.321 1.182-1.879 1.862-1.249 1.524-1.355 3.146.086 4.569 1.536 1.516 3.076 1.042 4.387-.401 3.68-4.053 8.331-4.769 13.398-4.087 1.445.194 2.794.69 3.98 1.571 3.392 2.521 3.437 6.912.08 9.48-1.896 1.45-4.126 2.099-6.405 2.588-2.737.587-5.479 1.136-8.03 2.377-4.994 2.427-7.337 6.281-6.889 11.384.465 5.304 3.644 9.033 8.947 10.429C-3.044-.204-1.308.084 0 0'
        transform='translate(393.914 468.234)'
      />
      <path
        style={{
          fill: bgColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0c-.001-7.048.001-14.095-.005-21.143 0-.614.072-1.255-.1-1.838-.322-1.081-1.128-1.811-2.533-1.82-1.407-.009-2.217.728-2.565 1.791-.189.576-.105 1.222-.105 1.837-.004 14.027-.004 28.053-.004 42.08 0 .479-.008.958.016 1.436.078 1.515.915 2.515 2.766 2.495 1.779-.019 2.499-1.05 2.509-2.464.026-3.353.02-6.706.022-10.058C.003 8.21.001 4.105 0 0'
        transform='translate(357.46 450.695)'
      />
      <path
        style={{
          fill: 'none',
          stroke: fillColor,
          strokeWidth: 1,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d='M0 0c-.001-7.048.001-14.095-.005-21.143 0-.614.072-1.255-.1-1.838-.322-1.081-1.128-1.811-2.533-1.82-1.407-.009-2.217.728-2.565 1.791-.189.576-.105 1.222-.105 1.837-.004 14.027-.004 28.053-.004 42.08 0 .479-.008.958.016 1.436.078 1.515.915 2.515 2.766 2.495 1.779-.019 2.499-1.05 2.509-2.464.026-3.353.02-6.706.022-10.058C.003 8.21.001 4.105 0 0Z'
        transform='translate(357.46 450.695)'
      />
      <path
        style={{
          fill: bgColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0c-.366-.373-.6-.525-.691-.716-2.821-5.942-5.604-11.895-8.455-17.828-.552-1.147-.424-1.635 1.385-1.605 5.161.084 10.326.107 15.489.072 1.758-.012 2.001.429 1.411 1.601C7.365-14.953 5.697-11.4 3.958-7.868 2.694-5.3 1.384-2.746 0 0m-21.752-33.978c.314 1.799 1.521 3.899 2.456 6.06C-13.845-15.337-8.318-2.78-2.81 9.782c.534 1.216 1.003 2.54 2.948 2.548 1.91.007 2.442-1.231 2.999-2.477C9.414-4.195 15.706-18.238 21.99-32.284c.171-.381.306-.774.453-1.163.598-1.574.262-2.967-1.649-3.543-1.896-.571-2.946.497-3.569 1.93-1.108 2.552-2.336 5.07-3.31 7.659-.583 1.551-1.514 2.145-3.442 2.111a833.28 833.28 0 0 0-20.309-.098c-1.837.013-2.768-.608-3.344-2.018-1.1-2.701-2.3-5.372-3.465-8.053-.56-1.292-1.506-2.144-3.248-1.756-1.596.356-1.988 1.434-1.859 3.237'
        transform='translate(325.986 463.204)'
      />
      <path
        style={{
          fill: 'none',
          stroke: fillColor,
          strokeWidth: 1,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d='M0 0c-.366-.373-.6-.525-.691-.716-2.821-5.942-5.604-11.895-8.455-17.828-.552-1.147-.424-1.635 1.385-1.605 5.161.084 10.326.107 15.489.072 1.758-.012 2.001.429 1.411 1.601C7.365-14.953 5.697-11.4 3.958-7.868 2.694-5.3 1.384-2.746 0 0Zm-21.752-33.978c.314 1.799 1.521 3.899 2.456 6.06C-13.845-15.337-8.318-2.78-2.81 9.782c.534 1.216 1.003 2.54 2.948 2.548 1.91.007 2.442-1.231 2.999-2.477C9.414-4.195 15.706-18.238 21.99-32.284c.171-.381.306-.774.453-1.163.598-1.574.262-2.967-1.649-3.543-1.896-.571-2.946.497-3.569 1.93-1.108 2.552-2.336 5.07-3.31 7.659-.583 1.551-1.514 2.145-3.442 2.111a833.28 833.28 0 0 0-20.309-.098c-1.837.013-2.768-.608-3.344-2.018-1.1-2.701-2.3-5.372-3.465-8.053-.56-1.292-1.506-2.144-3.248-1.756-1.596.356-1.988 1.434-1.859 3.237z'
        transform='translate(325.986 463.204)'
      />
      <path
        style={{
          fill: fillColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0c2.847 6.928 5.672 13.793 8.489 20.663 1.492 3.638 2.952 7.289 4.458 10.921.702 1.693 1.629 3.162 3.847 2.38 1.661-.586 2.328-2.458 1.45-4.589C13.127 16.952 7.957 4.552 2.793-7.851c-.565-1.357-1.416-2.423-3.073-2.396-1.574.025-2.283 1.128-2.81 2.378-5.219 12.38-10.443 24.758-15.616 37.157-.912 2.187-.309 4 1.396 4.583 2.177.743 3.126-.638 3.844-2.382C-9.473 21.794-5.473 12.101-1.45 2.418-1.118 1.619-1.031.649 0 0'
        transform='translate(273.465 433.89)'
      />
      <path
        style={{
          fill: fillColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0c3.666 0 7.332.035 10.997-.02 1.217-.018 1.782.272 1.508 1.615-.884 4.325-2.758 7.99-6.824 10.134-7.644 4.028-18.019-2.156-18.072-10.706-.008-1.429.961-.999 1.633-1.005C-7.172-.02-3.586 0 0 0m2.019-5.379c-4.155 0-8.313-.099-12.464.043-1.961.066-2.289-.584-1.926-2.354 2.184-10.641 13.38-15.092 22.246-8.788 1.359.968 2.72 1.881 4.302.527 1.521-1.3 1.198-3.341-.698-4.884-10.367-8.432-25.169-4.562-29.95 8.118-3.12 8.278-2.617 16.594 2.507 24.04 7.705 11.195 23.979 9.718 29.818-2.552 1.64-3.447 2.278-7.126 2.321-10.922.025-2.17-1.007-3.239-3.212-3.225-4.315.027-8.63.008-12.944.008z'
        transform='translate(231.439 448.866)'
      />
      <path
        style={{
          fill: fillColor,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d='M0 0c-.041-4.147 1.239-7.821 3.797-11.088 4.624-5.904 13.081-6.754 18.669-1.837 6.39 5.623 7.543 16.423 2.488 23.316-4.922 6.711-13.445 7.699-19.651 2.204C1.544 9.267.038 4.923 0 0m27.404 14.888c.85.946.541 1.778.545 2.522.031 5.989.007 11.979.031 17.967.004.869.078 1.763.3 2.597.355 1.326 1.377 1.951 2.705 1.908 1.343-.043 2.254-.784 2.56-2.111.143-.615.163-1.268.165-1.904.036-11.818.065-23.636.085-35.453.011-6.481-1.787-12.294-6.455-16.991-7.414-7.461-19.605-7.296-26.854.336-8.431 8.877-8.115 24.926.655 33.296 7.355 7.019 18.732 6.553 25.409-1.046.298-.339.55-.719.854-1.121'
        transform='translate(172.042 445.668)'
      />
    </g>
  </svg>
)

export default Devais

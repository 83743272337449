import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../components/Containers/Card'
import Divider from '../components/Divider'
import PrimaryButton from '../components/Inputs/PrimaryButton'
import ManifestoItem from '../components/ManifestoItem'
import Subtitle from '../components/Typography/Subtitle'
import useMediaQuery from '../hooks/useMediaQueriees'

interface IManifesto {}

const Manifesto: React.FC<IManifesto> = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(1024)
  const router = useRouter()

  const items = [
    {
      number: 1,
      title: t('manifesto.pointOneCaps'),
      subtitle: t('manifesto.pointOne'),
      image: '/assets/images/manifesto/goal.png',
    },
    {
      number: 2,
      title: t('manifesto.pointTwoCaps'),
      subtitle: t('manifesto.pointTwo'),
      image: '/assets/images/manifesto/love.png',
    },
    {
      number: 3,
      title: t('manifesto.pointThreeCaps'),
      subtitle: t('manifesto.pointThree'),
      image: '/assets/images/manifesto/rnd.png',
    },
    {
      number: 4,
      title: t('manifesto.pointFourCaps'),
      subtitle: t('manifesto.pointFour'),
      image: '/assets/images/manifesto/factory.png',
    },
  ]

  return (
    <>
      <section className='bg-gradient-cyan-violet relative' id='manifesto'>
        <Divider fillColor='#FFFFFF11' className='absolute top-1/4' />
        <div className='mx-auto container py-20 flex flex-col justify-start items-center px-4'>
          <div className='relative'>
            <img
              width={'100%'}
              height={'100%'}
              src='/assets/images/common/desktop-dot.svg'
              className='absolute h-full hidden lg:block'
              alt='desktop dot'
            />
            <img
              width={'100%'}
              height={'100%'}
              src='/assets/images/common/mobile-dot.svg'
              className='h-full absolute overflow-hidden block lg:hidden'
              alt='mobile dot'
            />
            {items.map((item, index) => (
              <ManifestoItem key={index} {...item} />
            ))}
          </div>

          <div className='flex flex-col-reverse lg:flex-row justify-center items-center w-full lg:w-2/3'>
            <div className='flex flex-col justify-center items-start pb-4 xl:px-10 xl:pb-10 -mt-20 xl:-mt-20 2xl:-mt-10 w-full lg:w-3/5'>
              <Card className='animate-mover'>
                <span className='z-30'>
                  <Subtitle
                    label={t('manifestoProject.title')}
                    align='text-left'
                    className='-mt-10'
                  />
                </span>
                <h3 className='text-white font-normal text-xl m-4'>
                  {t('manifestoProject.description')}
                </h3>
                <div className='flex flex-row justify-center items-center -mb-10'>
                  <PrimaryButton
                    title={t('manifestoProject.cta')}
                    bgClass='bg-orange'
                    bgShadowClass='bg-brown'
                    border='border-brown'
                    full
                    onClick={() => router.push('/factory-workers')}
                  />
                </div>
              </Card>
            </div>

            {isMobile ? (
              <img
                src={'/assets/images/common/fwGeneratorMobile.png'}
                alt='Illustration factory workers'
                className='w-full'
              />
            ) : (
              <img
                src={'/assets/images/common/fwGenerator.png'}
                alt='Illustration factory workers'
                className='w-1/2'
              />
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Manifesto

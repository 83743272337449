import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight, FaDiscord, FaTwitter } from 'react-icons/fa'
import { SiTelegram } from 'react-icons/si'
import PrivilegesCard from '../components/PrivilegesCard'
import SectionEndCurvy from '../components/SVG/SectionEndCurvy'
import Subtitle from '../components/Typography/Subtitle'
import styles from '../styles/InputButton.module.scss'
import Footer from './Footer'
import { MdAlternateEmail } from 'react-icons/md'
import useMediaQuery from '../hooks/useMediaQueriees'
import colors from '../utils/colors'

interface IMainFaq {}

const MainFaq: React.FC<IMainFaq> = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const isMobile = useMediaQuery(1024)

  const onSubmit = () => {
    fetch('/api/mailchimp', {
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(() => setEmail(''))
      .catch(() => setError(true))
  }

  const faq = [
    {
      title: t('faq.faq1.title'),
      description: t('faq.faq1.description'),
    },
    {
      title: t('faq.faq2.title'),
      description: t('faq.faq2.description'),
    },
    {
      title: t('faq.faq3.title'),
      description: t('faq.faq3.description'),
    },
    {
      title: t('faq.faq4.title'),
      description: t('faq.faq4.description'),
    },
    {
      title: t('faq.faq5.title'),
      description: t('faq.faq5.description'),
    },
    {
      title: t('faq.faq6.title'),
      description: t('faq.faq6.description'),
    },
  ]

  return (
    <>
      <section className='bg-darkViolet h-full'>
        <SectionEndCurvy svgColor={colors.cyan} />
        <div className='py-10 px-2'>
          <div className='container mx-auto '>
            <Subtitle label={t('stayUpdated.title')} />

            <div className='w-full flex flex-row justify-center items-center mt-20 px-4'>
              <div className='w-full lg:w-1/2 bg-white rounded-3xl border-[3px] border-black box-shadow-lg pl-5 lg:pl-10 pr-0 flex flex-row justify-between items-center'>
                <input
                  className={`${styles.input} text-lg lg:text-3xl text-black font-semibold bg-transparent z-40 pr-5 lg:pr-10 my-4`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={t('stayUpdated.email')}
                />
                <div
                  className='h-[74px] w-[100px] lg:w-[200px] cursor-pointer bg-orange flex flex-row justify-center items-center rounded-r-3xl border-[3px] border-black -mr-[4px] -mt-[3px]'
                  onClick={onSubmit}
                >
                  <FaArrowRight size={isMobile ? 30 : 40} color='white' />
                </div>
              </div>
            </div>

            <div className='flex flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start lg:mx-4 w-full mt-20'>
              <div className='flex flex-col justify-center items-center mt-4 lg:mr-10'>
                <Subtitle label={t('faq.subtitle')} />
                <h4 className='text-white font-light text-md lg:text-xl text-center mt-4'>
                  {t('faq.description')}
                </h4>
                <div className='flex-row justify-start items-center flex mt-4'>
                  <FaDiscord
                    color={colors.white}
                    onClick={() => {
                      open('https://discord.gg/acUsZMBBcR')
                    }}
                    size={'2vh'}
                    className='cursor-pointer'
                    title='Discord'
                  />

                  <FaTwitter
                    color={colors.white}
                    onClick={() => {
                      open('https://twitter.com/NFTFactoryOG')
                    }}
                    size={'2vh'}
                    className='ml-5 cursor-pointer'
                    title='Instagram'
                  />

                  <SiTelegram
                    color={colors.white}
                    onClick={() => {
                      open('https://t.me/NFTFactoryANNOUNCEMENTS')
                    }}
                    size={'2vh'}
                    className='ml-5 cursor-pointer'
                    title='Telegram'
                  />
                </div>
                <h3 className='text-white font-semibold text-sm lg:text-lg text-center mt-4'>
                  {t('faq.orWrite')}
                </h3>
                <div
                  className='text-cyan font-semibold text-sm lg:text-lg text-center mt-4 flex flex-row items-center justify-center cursor-pointer'
                  onClick={() => {
                    open('https://t.me/NFTFactoryANNOUNCEMENTS')
                  }}
                >
                  <SiTelegram color={colors.white} className='mr-2' />
                  {t('faq.nftFactory')}
                </div>
                <div
                  className='text-cyan font-semibold text-sm lg:text-lg text-center mt-4 flex flex-row items-center justify-center cursor-pointer'
                  onClick={() => {
                    open('mailto:ciao@nft-factory.club')
                  }}
                >
                  <MdAlternateEmail color={colors.white} className='mr-2' />
                  ciao@nft-factory.club
                </div>
              </div>
              <div className='flex flex-col justify-start items-center w-full lg:w-2/3'>
                <div className='flex flex-row justify-center items-stretch flex-wrap h-max relative p-4 w-full'>
                  <PrivilegesCard items={faq} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='bg-gray'>
        <SectionEndCurvy svgColor={colors.darkViolet} />
      </div>
      <Footer />
    </>
  )
}

export default MainFaq

import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '../../hooks/useMediaQueriees'
import colors from '../../utils/colors'
import HeaderTitle from '../Typography/HeaderTitle'

interface IHeaderBar {}

const HeaderBar: React.FC<IHeaderBar> = () => {
  const isMobile = useMediaQuery(1024)

  const { t } = useTranslation()

  return (
    <div className='w-full relative flex flex-col justify-start items-center z-20'>
      <div className='w-full flex flex-col justify-center items-start bg-violet'>
        <div className='container mx-auto flex flex-col justify-center items-start pb-10 pt-20 px-4'>
          <div className='flex flex-row justify-start items-end mb-4'>
            <Image
              src={'/assets/images/common/logo.png'}
              width={96}
              height={101}
              alt='NFT Factory logo'
            />
            <h1 className='text-white font-black text-3xl lg:text-5xl ml-2 lg:mb-[2px]'>
              NFT FACTORY
            </h1>
          </div>
          <HeaderTitle label={t('header.title')} align='text-left' />
          <h3 className='text-white font-normal mt-4 text-md lg:text-xl lg:w-2/3'>
            {t('header.description')}
          </h3>
        </div>
      </div>
      {isMobile ? (
        <svg
          viewBox='0 0 353 48'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='w-full'
        >
          <g clipPath='url(#clip0_1262_434)'>
            <path
              d='M-10 -308L356 -308V34.9266C356 34.9266 287.338 47 231.242 47C199.496 47 182.676 20.5895 155.192 20.5895C111.818 20.5895 84.0369 38.2175 50.1534 27.4646C16.27 16.7118 -10 20.5895 -10 20.5895V-308Z'
              fill='black'
            />
            <path
              d='M0 -318L366 -318V24.9266C366 24.9266 297.338 37 241.242 37C209.496 37 192.676 10.5895 165.192 10.5895C121.818 10.5895 94.0369 28.2175 60.1534 17.4646C26.27 6.7118 0 10.5895 0 10.5895V-318Z'
              fill={colors.violet}
            />
          </g>
          <defs>
            <clipPath id='clip0_1262_434'>
              <rect width='353' height='48' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='w-full'
          viewBox='0 0 1515 147'
        >
          <g clipPath='url(#clip0_1245_427)'>
            <path
              d='M-20 -373.997H1515V94.3587C1515 94.3587 1396.14 146.503 1157.9 146.503C1023.07 146.503 951.634 32.4373 834.91 32.4373C650.695 32.4373 532.707 108.572 388.8 62.1306C244.894 15.6895 -20 32.4373 -20 32.4373V-373.997Z'
              fill='black'
            />
            <path
              d='M0 -394H1535V74.3555C1535 74.3555 1416.14 126.5 1177.9 126.5C1043.07 126.5 971.634 12.4341 854.91 12.4341C670.695 12.4341 552.707 88.5685 408.8 42.1274C264.894 -4.31372 0 12.4341 0 12.4341V-394Z'
              fill={colors.violet}
            />
          </g>
          <defs>
            <clipPath id='clip0_1245_427'>
              <rect width='1515' height='147' fill='white' />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  )
}

export default HeaderBar

import Image from 'next/image'
import React from 'react'
import {
  FaArtstation,
  FaDribbble,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa'
import { GiEarthAfricaEurope } from 'react-icons/gi'
import colors from '../utils/colors'

interface ITeam {
  username: string
  image: string
  instagram?: string
  linkedin?: string
  website?: string
  dribbble?: string
  twitter?: string
  artstation?: string
  role: string
}

interface ITeamItem {
  social?: boolean
  showText?: boolean
  team: ITeam
}

const TeamItem: React.FC<ITeamItem> = ({
  team,
  showText = true,
  social = true,
}) => {
  return (
    <div className='w-36 lg:w-72 flex flex-col items-center justify-start mx-2 lg:mx-10 my-5 lg:my-2'>
      <Image
        src={team.image}
        loading='lazy'
        alt='Team member'
        width={300}
        height={300}
      />
      {showText && (
        <>
          <h3 className='text-lg lg:text-2xl text-white font-semibold text-center'>
            {team.username}
          </h3>

          <h4 className='text-sm lg:text-lg text-white font-thin text-center h-10'>
            {team.role}
          </h4>
        </>
      )}
      {social && (
        <div className='flex-row items-center justify-center flex mt-2'>
          {team.instagram && (
            <FaInstagram
              onClick={() => open(team.instagram)}
              color={colors.white}
              size={24}
              className='h-4 lg:h-6 cursor-pointer mx-1 lg:mx-2'
              title='Team Instagram'
            />
          )}

          {team.linkedin && (
            <FaLinkedin
              onClick={() => open(team.linkedin)}
              color={colors.white}
              size={24}
              className='h-4 lg:h-6 cursor-pointer mx-1 lg:mx-2'
              title='Team Linkedin'
            />
          )}

          {team.website && (
            <GiEarthAfricaEurope
              onClick={() => open(team.website)}
              color={colors.white}
              size={24}
              className='h-4 lg:h-6 cursor-pointer mx-1 lg:mx-2'
              title='Team Website'
            />
          )}

          {team.artstation && (
            <FaArtstation
              onClick={() => open(team.artstation)}
              color={colors.white}
              size={24}
              className='h-4 lg:h-6 cursor-pointer mx-1 lg:mx-2'
              title='Team Artstation'
            />
          )}

          {team.dribbble && (
            <FaDribbble
              onClick={() => open(team.dribbble)}
              color={colors.white}
              size={24}
              className='h-4 lg:h-6 cursor-pointer mx-1 lg:mx-2'
              title='Team Dribbble'
            />
          )}

          {team.twitter && (
            <FaTwitter
              onClick={() => open(team.twitter)}
              color={colors.white}
              size={24}
              className='h-4 lg:h-6 cursor-pointer mx-1 lg:mx-2'
              title='Team Twitter'
            />
          )}
          {!team.instagram &&
            !team.twitter &&
            !team.linkedin &&
            !team.website &&
            !team.dribbble && <div className='h-4 lg:h-6 mx-1 lg:mx-2' />}
        </div>
      )}
    </div>
  )
}

export default TeamItem

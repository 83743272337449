import Image from 'next/image'
import React from 'react'
import useMediaQuery from '../hooks/useMediaQueriees'
import NumberCircle from './NumberCircle'
import Subtitle from './Typography/Subtitle'

interface IManifestoItem {
  number: number
  title: string
  subtitle: string
  image: string
}

const ManifestoItem: React.FC<IManifestoItem> = ({
  number,
  title,
  subtitle,
  image,
}) => {
  const isMobile = useMediaQuery(1024)

  if (isMobile)
    return (
      <div
        className={`flex flex-col justify-center items-center w-full px-4 ${
          number === 1 ? 'mt-4' : 'mt-[70px]'
        }`}
      >
        <span className='mt-16'>
          <NumberCircle number={number.toString()} />
        </span>
        <div className='mt-[25px]'>
          <Image
            src={image}
            loading='lazy'
            alt='Illustration about section'
            width={960}
            height={540}
          />
        </div>

        <div className='w-3/4 flex flex-col justify-center items-center mt-[25px]'>
          <Subtitle label={title} align={'text-center'} self={'self-center'} />
          <h2
            className={`text-lg font-light text-white text-center self-center w-full mt-[20px]`}
          >
            {subtitle}
          </h2>
        </div>
      </div>
    )

  return (
    <div className='flex flex-col justify-center items-center w-full h-[500px] relative mt-10'>
      <div
        className={`flex ${
          number % 2 !== 0 && image ? 'flex-row' : 'flex-row-reverse'
        } justify-center items-center`}
      >
        <div className='w-1/2 flex flex-col justify-center items-center'>
          <NumberCircle number={number.toString()} />
          <Image
            width={480}
            height={270}
            layout='fixed'
            src={image}
            loading='lazy'
            alt='Illustration about section'
          />
        </div>

        <div className='w-2/4 flex flex-col justify-center items-center'>
          <Subtitle
            label={title}
            align={number % 2 !== 0 ? 'text-left' : 'text-right'}
            self={number % 2 !== 0 ? 'self-start' : 'self-end'}
          />
          <h2
            className={`text-2xl font-light text-white w-full mt-4 ${
              number % 2 !== 0 ? 'text-left self-start' : 'text-right self-end'
            }`}
          >
            {subtitle}
          </h2>
        </div>
      </div>
    </div>
  )
}

export default ManifestoItem

const team = [
  {
    username: 'portodianna',
    image: '/assets/images/team/pet.png',
    linkedin: 'https://www.linkedin.com/in/lorenzopetrangeli/',
    role: 'Chief Executive Officer',
  },

  {
    username: 'gcazzaniga.eth',
    image: '/assets/images/team/gcazzaniga.png',
    linkedin: 'https://www.linkedin.com/in/tommaso-paulon-86438013a/',
    twitter: 'https://twitter.com/goldmansucks_',
    role: 'Chief Technology Officer',
  },

  {
    username: 'uryon.eth',
    image: '/assets/images/team/Uryon.png',
    linkedin: 'https://www.linkedin.com/in/matteo-poli-b5b99512b/',
    twitter: 'https://twitter.com/MatteoPoli15',
    role: 'Chief Operating Officer',
  },

  {
    username: 'Nanni',
    image: '/assets/images/team/nanni.png',
    role: 'Game Lead Engineer',
  },

  {
    username: 'iltumio',
    image: '/assets/images/team/iltumio.png',
    linkedin: 'https://www.linkedin.com/in/manuel-tumiati/',
    website: 'https://iltumio.dev',
    role: 'Scientific Advisor',
  },

  {
    username: 'Cili',
    image: '/assets/images/team/cili.png',
    linkedin: 'https://www.linkedin.com/in/andreaciliberti/',
    role: 'Business Advisor',
  },

  {
    username: 'Grev',
    image: '/assets/images/team/grev.png',
    linkedin: 'https://www.linkedin.com/in/gabriele-licheri-182b1a110/',
    dribbble: 'https://dribbble.com/grevz',
    instagram: 'https://www.instagram.com/gr3vz/',
    role: 'UX/UI Lead',
  },

  {
    username: 'AB Pokerista',
    image: '/assets/images/team/pb.png',
    instagram: 'https://www.instagram.com/porkabestia/?hl=it',
    role: 'Lead Artist',
  },

  {
    username: 'Gioy',
    image: '/assets/images/team/gioy.png',
    instagram: 'https://www.instagram.com/gioiallievi/',
    linkedin: 'https://www.linkedin.com/in/gioia-allievi-b5854118b/',
    artstation: 'https://www.artstation.com/gioy',
    role: 'Artist',
  },

  {
    username: 'Cri',
    image: '/assets/images/team/cri.png',
    linkedin: 'http://www.linkedin.com/in/cristina-la-spada',
    artstation: 'https://www.artstation.com/lscris',
    instagram: 'https://www.instagram.com/cri_drawyoursword/',
    role: 'Artist',
  },

  {
    username: 'Hardin Finch',
    image: '/assets/images/team/hardin.png',
    twitter: 'https://twitter.com/criptofinch',
    website: 'https://medium.com/@hardin.finch',
    role: 'Community Manager',
  },
]

export default team

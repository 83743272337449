// Libraries
import React, { useEffect, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

// Components
import HeaderBarDesktop from '../components/SVG/HeaderBar'
import useMediaQuery from '../hooks/useMediaQueriees'
import colors from '../utils/colors'

interface IHeader {}

const Header: React.FC<IHeader> = () => {
  const isMobile = useMediaQuery(1024)
  const [animation, setAnimation] = useState<any>(null)

  useEffect(() => {
    if (isMobile !== -1)
      isMobile === 0
        ? fetch('/assets/animations/factory.json')
            .then((response) => response.json())
            .then((anim) => {
              setAnimation(anim)
            })
        : fetch('/assets/animations/factoryMobile.json')
            .then((response) => response.json())
            .then((anim) => setAnimation(anim))
  }, [isMobile])

  return (
    <section className='min-h-screen w-screen bg-cyan' id='home'>
      <HeaderBarDesktop />
      <div className='-mt-16 md:-mt-20 lg:-mt-60 2xl:-mt-40 3xl:-mt-[300px]'>
        {animation !== null && (
          <Player
            background={colors.cyan}
            autoplay
            loop
            src={animation}
            className='w-screen z-10'
          />
        )}
        {!animation && !isMobile && (
          <img
            src='/assets/images/common/factory.png'
            alt='Loading animation'
            className='w-screen z-10'
            width={1920}
            height={1080}
          />
        )}
        {!animation && isMobile && (
          <img
            src='/assets/images/common/factoryMobile.png'
            alt='Loading animation'
            className='w-screen z-10'
            width={941}
            height={1673}
          />
        )}
      </div>
    </section>
  )
}

export default Header

import styled from 'styled-components'
import * as BaseAccordion from '@radix-ui/react-accordion'
import { FaChevronDown, FaPlus } from 'react-icons/fa'

const Root = styled(BaseAccordion.Root)`
  width: 100%;
`

const Item = styled(BaseAccordion.Item)`
  width: 100%;
`

const Header = styled(BaseAccordion.Header)``

const Trigger = styled(BaseAccordion.Trigger)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Content = styled(BaseAccordion.Content)`
  margin-top: 20px;
  background-color: #7130c5;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  min-height: 150px;
  padding: 20px;
  border-radius: 20px;
`

const Chevron = styled(FaChevronDown)`
  [data-state='open'] & {
    transform: rotate(180deg);
  }
`

const Plus = styled(FaPlus)`
  [data-state='open'] & {
    transform: rotate(45deg);
  }
`

const Accordion = {
  Root,
  Item,
  Header,
  Trigger,
  Content,
  Chevron,
  Plus,
}

export default Accordion

// Libraries
import React from 'react'
import { Provider } from 'wagmi'
import NavbarDesktop from '../components/Navbars/NavbarDesktop'
import NavbarMobile from '../components/Navbars/NavbarMobile'
import AboutUs from '../containers/AboutUs'

// Components
import Header from '../containers/Header'
import MainFaq from '../containers/MainFaq'
import Manifesto from '../containers/Manifesto'
import Projects from '../containers/Projects'
import useMediaQuery from '../hooks/useMediaQueriees'
import { connectors, provider } from '../utils/blockchain'

const Home = () => {
  const isMobile = useMediaQuery(1024)

  return (
    <Provider connectors={connectors} provider={provider}>
      {!isMobile ? <NavbarDesktop /> : <NavbarMobile />}
      <Header />
      <Manifesto />
      <section className='bg-gradient-pink-cyan min-h-screen z-40'>
        <Projects />
        <AboutUs />
      </section>
      <MainFaq />
    </Provider>
  )
}

export default Home

import React from 'react'

interface ICard {
  children: any
  className?: string
}

const Card: React.FC<ICard> = ({ children, className = '' }) => {
  return (
    <div
      className={`relative bg-darkViolet rounded-3xl border-[3px] border-black box-shadow-lg ${className} ml-2 lg:ml-0 z-20`}
    >
      {children}
    </div>
  )
}

export default Card

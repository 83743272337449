import React from 'react'

interface INumberCircle {
  number: string
}

const NumberCircle: React.FC<INumberCircle> = ({ number }) => {
  return (
    <div className='flex flex-row justify-center items-center relative w-20 lg:w-36 animate-bounce'>
      <svg
        viewBox='0 0 64 64'
        fill='none'
        className='w-16 lg:w-24'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <g filter='url(#filter0_i_536_4)'>
          <circle r='32' transform='matrix(-1 0 0 1 32 32)' fill='#DBB622' />
        </g>
        <circle
          r='29.2029'
          transform='matrix(-1 0 0 1 32 32)'
          stroke='#DBB622'
          strokeWidth='5.59424'
        />
        <defs>
          <filter
            id='filter0_i_536_4'
            x='0'
            y='0'
            width='64'
            height='65'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='BackgroundImageFix'
              result='shape'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feMorphology
              radius='7'
              operator='erode'
              in='SourceAlpha'
              result='effect1_innerShadow_536_4'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='2' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.858824 0 0 0 0 0.514898 0 0 0 0 0.133333 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='shape'
              result='effect1_innerShadow_536_4'
            />
          </filter>
        </defs>
      </svg>

      <h2 className='text-xl lg:text-5xl font-extrabold text-white p-1 absolute'>
        {number}
      </h2>
    </div>
  )
}

export default NumberCircle

import Image from 'next/image'
import React from 'react'
import PrimaryButton from '../Inputs/PrimaryButton'
import Subtitle from '../Typography/Subtitle'
import Card from './Card'

interface IProjectCard {
  bgColor: string

  imagePath: string
  title: string
  description: string
  cta?: string
  badge: () => any

  bgClass?: string
  bgShadowClass?: string
  border?: string
  onClick?: () => void

  reverse?: boolean
}

const ProjectCard: React.FC<IProjectCard> = ({
  bgColor,
  title,
  imagePath,
  description,
  bgClass,
  bgShadowClass,
  border,
  cta,
  badge,
  onClick,
  reverse = false,
}) => {
  return (
    <div className='w-full 2xl:w-2/3 mt-20'>
      <Card className={`${bgColor}`}>
        <div
          className={`flex flex-col ${
            reverse ? 'xl:flex-row-reverse' : 'xl:flex-row'
          } justify-center items-start px-4 pb-4 xl:px-10 xl:pb-10 -mt-20 xl:-mt-20 2xl:-mt-10`}
        >
          <span className='z-10 flex flex-row justify-center items-start w-full'>
            <Image src={imagePath} height={800} width={800} />
          </span>
          <div className='flex flex-col justify-start items-center xl:w-9/12 -mt-20 lg:-mt-40 xl:mt-0 z-20'>
            <Subtitle label={title} align='text-left' self='self-start' />
            {badge()}
            <h3 className='text-white text-lg lg:text-2xl 2xl:text-3xl mt-6 self-start text-left font-light'>
              {description}
            </h3>
            {onClick && cta && (
              <div className='mt-4 self-start -mb-16'>
                <PrimaryButton
                  full
                  title={cta}
                  onClick={onClick}
                  bgClass={bgClass}
                  bgShadowClass={bgShadowClass}
                  border={border}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ProjectCard

import React from 'react'
import useMediaQuery from '../../hooks/useMediaQueriees'

interface ISubtitle {
  label: string
  align?: 'text-center' | 'text-left' | 'text-right'
  self?: 'self-center' | 'self-start' | 'self-end'
  className?: string
}

const Subtitle: React.FC<ISubtitle> = ({
  label,
  align = 'text-center',
  self = 'self-center',
  className,
}) => {
  const isMobile = useMediaQuery(1024)

  return (
    <span className={`relative ${self}`}>
      <h3
        className={`font-black text-yellow ${
          isMobile ? 'text-shadow-6' : 'text-shadow-10'
        } text-5xl lg:text-6xl text-stroke-2 lg:text-stroke-3 leading-[40px] lg:leading-[55px] -tracking-wide subpixel-antialiased ${align} ${className}`}
      >
        {label}
      </h3>
    </span>
  )
}

export default Subtitle

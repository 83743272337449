import React from 'react'

interface IDivider {
  fillColor?: string
  className?: string
  onBlur?: () => void
  onFocus?: () => void
}

const Divider: React.FC<IDivider> = ({
  fillColor = '#F0950D',
  className,
  onBlur,
  onFocus,
}) => (
  <svg
    viewBox='0 0 1440 361'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onBlur={onBlur}
    onFocus={onFocus}
    onMouseLeave={onBlur}
    onMouseEnter={onFocus}
  >
    <path
      d='M1143.35 165.492C1244.63 153.212 1350.98 171.676 1441 236.5V53.8598C1336.21 2.1055 1229.41 -7.12195 1123.97 5.66247C1010.34 19.4419 894.115 59.6345 784.04 97.7011C770.886 102.25 757.82 106.769 744.857 111.208C497.155 196.038 260.655 261.452 0 103.174V285.74C296.242 424.921 568.771 341.691 797.019 263.524C810.02 259.072 822.831 254.655 835.466 250.299L835.508 250.285L835.508 250.285C948.718 211.255 1047.88 177.069 1143.35 165.492Z'
      fill={fillColor}
    />
  </svg>
)

export default Divider

import * as React from 'react'
import colors from '../../utils/colors'

interface ISectionEndCurvy {
  svgColor?: string
}

const SectionEndCurvy: React.FC<ISectionEndCurvy> = ({
  svgColor = colors.violet,
}) => (
  <svg
    viewBox='0 0 1464 304'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='z-30'
  >
    <g clipPath='url(#clip0_1325_1933)'>
      <path
        d='M-20 16H1464V202.8C1464 202.8 1234.7 430.5 722 202.8C209.299 -24.9011 -20 202.8 -20 202.8V16Z'
        fill='black'
      />
      <path
        d='M0 0H1484V186.8C1484 186.8 1254.7 414.5 742 186.8C229.299 -40.9011 0 186.8 0 186.8V0Z'
        fill={svgColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_1325_1933'>
        <rect width='1464' height='304' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default SectionEndCurvy

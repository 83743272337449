import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Virtual } from 'swiper'
import TeamItem from '../components/TeamItem'
import team from '../utils/teamData'
import Subtitle from '../components/Typography/Subtitle'
import { useTranslation } from 'react-i18next'
import Knobs from '../components/PartnersLogo/Knobs'
import BCode from '../components/PartnersLogo/BCode'
import Devais from '../components/PartnersLogo/Devais'
import NumberCircle from '../components/NumberCircle'
import Card from '../components/Containers/Card'
import useMediaQuery from '../hooks/useMediaQueriees'
import PrimaryButton from '../components/Inputs/PrimaryButton'
import colors from '../utils/colors'

interface IAboutUs {}

const AboutUs: React.FC<IAboutUs> = () => {
  const isMobile = useMediaQuery(1024)
  const { t } = useTranslation()

  const [knobsHover, setKnobsHover] = useState(false)
  const [bcodeHover, setBcodeHover] = useState(false)
  const [devaisHover, setDevaisHover] = useState(false)

  SwiperCore.use([Autoplay])

  const breakpoints = {
    // when window width is >= 320px
    0: {
      slidesPerView: 2,
    },
    // when window width is >= 480px
    641: {
      slidesPerView: 2,
    },
    // when window width is >= 640px
    769: {
      slidesPerView: 3,
    },

    1025: {
      slidesPerView: 3,
    },

    1281: {
      slidesPerView: 4,
    },

    1537: {
      slidesPerView: 5,
    },
  }

  return (
    <div
      className='container mx-auto flex flex-col justify-start items-center pb-20 px-4'
      id='services'
    >
      <div className='mt-40 flex flex-col justify-start items-center'>
        <Subtitle label={t('b2b.title')} />
        <h3 className='text-white text-lg lg:text-2xl mt-10 w-full lg:w-2/3 text-center'>
          {t('b2b.description')}
        </h3>
        {!isMobile && (
          <>
            <div className='relative flex flex-row justify-between items-center w-full mt-20'>
              <img
                src='/assets/images/common/horizontalLine.svg'
                className='w-full absolute'
              />
              <NumberCircle number='1' />
              <NumberCircle number='2' />
              <NumberCircle number='3' />
            </div>
            <div className='relative flex flex-row justify-between items-center w-full mt-32'>
              <Card className='w-[28%] animate-mover'>
                <div className='flex flex-col justify-center items-start -mt-20 2xl:-mt-10'>
                  <span className='z-30'>
                    <Subtitle
                      label={t('b2b.card.firstTitle')}
                      align='text-left'
                    />
                  </span>
                  <h3 className='text-white font-normal text-xl m-10'>
                    {t('b2b.card.firstDesc')}
                  </h3>
                </div>
              </Card>
              <Card className='w-[28%] animate-mover animation-delay-300'>
                <div className='flex flex-col justify-center items-start -mt-20 2xl:-mt-10'>
                  <span className='z-30'>
                    <Subtitle
                      label={t('b2b.card.secondTitle')}
                      align='text-left'
                    />
                  </span>
                  <h3 className='text-white font-normal text-xl m-10'>
                    {t('b2b.card.secondDesc')}
                  </h3>
                </div>
              </Card>
              <Card className='w-[28%] animate-mover animation-delay-600'>
                <div className='flex flex-col justify-center items-start -mt-20 2xl:-mt-10'>
                  <span className='z-30'>
                    <Subtitle
                      label={t('b2b.card.thirdTitle')}
                      align='text-left'
                    />
                  </span>
                  <h3 className='text-white font-normal text-xl m-10'>
                    {t('b2b.card.thirdDesc')}
                  </h3>
                </div>
              </Card>
            </div>
          </>
        )}
        {isMobile === 1 && (
          <div className='relative flex flex-col justify-start items-center w-full mt-20'>
            <img
              width={'100%'}
              height={'100%'}
              src='/assets/images/common/mobile-dot.svg'
              className='h-full absolute overflow-hidden block md:hidden'
              alt='mobile dot'
            />

            <NumberCircle number='1' />

            <Card className='mt-20'>
              <div className='flex flex-col justify-center items-start -mt-10 '>
                <span className='z-30'>
                  <Subtitle
                    label={t('b2b.card.firstTitle')}
                    align='text-left'
                  />
                </span>
                <h3 className='text-white font-normal text-xl m-10'>
                  {t('b2b.card.firstDesc')}
                </h3>
              </div>
            </Card>

            <div className='mt-20' />

            <NumberCircle number='2' />

            <Card className='mt-20'>
              <div className='flex flex-col justify-center items-start -mt-10 '>
                <span className='z-30'>
                  <Subtitle
                    label={t('b2b.card.secondTitle')}
                    align='text-left'
                  />
                </span>
                <h3 className='text-white font-normal text-xl m-10'>
                  {t('b2b.card.secondDesc')}
                </h3>
              </div>
            </Card>

            <div className='mt-20' />

            <NumberCircle number='3' />

            <Card className='mt-20'>
              <div className='flex flex-col justify-center items-start -mt-10 '>
                <span className='z-30'>
                  <Subtitle
                    label={t('b2b.card.thirdTitle')}
                    align='text-left'
                  />
                </span>
                <h3 className='text-white font-normal text-xl m-10'>
                  {t('b2b.card.thirdDesc')}
                </h3>
              </div>
            </Card>
          </div>
        )}
        <div className='mt-20'>
          <PrimaryButton
            full
            bgClass='bg-orange'
            border='border-brown'
            bgShadowClass='bg-brown'
            title={t('b2b.cta')}
            onClick={() => open('mailto:ciao@nft-factory.club')}
          />
        </div>
      </div>

      <div className='bg-darkViolet w-full rounded-[50px] py-10 px-2 lg:py-20 flex flex-row justify-center items-center relative mt-40'>
        <div className='absolute -top-6 left-6 lg:left-20'>
          <Subtitle label={t('team.title')} />
        </div>
        <Swiper
          modules={[Virtual]}
          virtual
          centeredSlides
          centeredSlidesBounds
          centerInsufficientSlides
          grabCursor
          breakpoints={breakpoints}
          autoplay={{ delay: 1000 }}
        >
          {team.map((member, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <TeamItem team={member} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <h3 className='text-white mt-[70px] font-semibold text-3xl'>
        {t('partners.title')}
      </h3>
      <div className='flex flex-col lg:flex-row justify-center items-center'>
        <Knobs
          fillColor={knobsHover ? '#FFFFFFAA' : colors.white}
          className={'w-2/5 md:w-1/5 mx-20 mt-10 cursor-pointer'}
          onBlur={() => setKnobsHover(false)}
          onFocus={() => setKnobsHover(true)}
          onClick={() => open('https://knobs.it')}
        />
        <BCode
          fillColor={bcodeHover ? '#FFFFFFAA' : colors.white}
          className={'w-2/5 md:w-1/5 mx-20 mt-10 cursor-pointer'}
          onBlur={() => setBcodeHover(false)}
          onFocus={() => setBcodeHover(true)}
          onClick={() => open('https://bcode.cloud')}
        />
        <Devais
          fillColor={devaisHover ? '#FFFFFFAA' : colors.white}
          className={'w-2/5 md:w-1/5 mx-20 mt-10 cursor-pointer'}
          onBlur={() => setDevaisHover(false)}
          onFocus={() => setDevaisHover(true)}
          bgColor={colors.cyan}
          onClick={() => open('https://devais.it')}
        />
      </div>
    </div>
  )
}

export default AboutUs
